import { withDefaultProps } from '@aspectus/vue-utils'
import { Descriptor, NoneController } from '@aspectus/vee-control-descriptor'
import UnifiedControlElement from './UnifiedControlElement'
import RangeControl from './RangeControl'
import CheckboxControl from './CheckboxControl'
import InputAction from './InputAction'
import StaticLabel from './StaticLabel'
import ErrorComponent from './ErrorComponent'

const Multiselect = () => import('./Multiselect')

const composerForInput = (input, action, label = StaticLabel) => withDefaultProps({
  labelComponent: () => label,
  element: () => input,
  actionComponent: () => action,
})(UnifiedControlElement)

const composer = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  bails: true,
  controllerComponent: () => NoneController,
})(Descriptor)

const composerCommon = input => withDefaultProps({
  element: () => input,
  labelComponent: null,
  errorComponent: () => ErrorComponent,
  bails: true,
  controllerComponent: () => NoneController,
})(Descriptor)

// input
const InputWithLabel = composerForInput('Input')
const ControlInput = composer(InputWithLabel)

// input without label
const PureInput = composerForInput('Input', null, null)
const ControlPureInput = composer(PureInput)

// // input with action
const InputWithAction = composerForInput('Input', InputAction)
const ControlInputAction = composer(InputWithAction)

// // input disappear label
// const InputWithDisappearLabel = composerForInput('Input', null, DisappearLabel)
// const ControlInputDisappearLabel = composer(InputWithDisappearLabel)

// multiselect
const MultiselectWithLabel = composerForInput(Multiselect, StaticLabel)
const ControlMultiselect = composer(MultiselectWithLabel)

// multiselect with autocomplete
const CommonMultiselect = composerForInput(Multiselect, StaticLabel)
const ControlMultiselectCommon = composerCommon(CommonMultiselect)

// multiselect pure
const MultiselectPure = composerForInput(Multiselect, null)
const ControlMultiselectPure = composer(MultiselectPure)

// action multiselect
const ActionMultiselect = composerForInput(Multiselect, InputAction, StaticLabel)
const MultiselectWithAction = composer(ActionMultiselect)

// textarea
const TextareaWithLabel = composerForInput('Textarea')
const ControlTextarea = composer(TextareaWithLabel)

// // textarea disappear label
// const TextareaWithDisappearLabel = composerForInput('Textarea', DisappearLabel)
// const ControlTextareaDisappearLabel = composer(TextareaWithDisappearLabel)

// export default function install(Vue, { prefix = 'DControl' } = {}) {
//   Vue.component(`${prefix}Input`, ControlInput)
//   Vue.component(`${prefix}PureInput`, ControlPureInput)
//   Vue.component(`${prefix}Multiselect`, ControlMultiselect)
//   Vue.component(`${prefix}InputDisappear`, ControlInputDisappearLabel)
//   Vue.component(`${prefix}TextareaDisappear`, ControlTextareaDisappearLabel)
//   Vue.component('Multiselect', Multiselect)
//   Vue.component('RangeComponent', RangeComponent)
//   Vue.component('RangeControl', RangeControl)
//   Vue.component('CheckboxControl', CheckboxControl)
//   Vue.component('RadioControl', RadioControl)
// }

export default function install(Vue, { prefix = 'Control' } = {}) {
  Vue.component('Multiselect', Multiselect)
  Vue.component(RangeControl.name, RangeControl)
  Vue.component(CheckboxControl.name, CheckboxControl)
  Vue.component(`${prefix}Input`, ControlInput)
  Vue.component(`${prefix}InputPure`, ControlPureInput)
  Vue.component(`${prefix}Textarea`, ControlTextarea)
  Vue.component(`${prefix}Multiselect`, ControlMultiselect)
  Vue.component(`${prefix}MultiselectPure`, ControlMultiselectPure)
  Vue.component(`${prefix}MultiselectAction`, MultiselectWithAction)
  Vue.component(`${prefix}InputAction`, ControlInputAction)
  Vue.component(`${prefix}MultiselectCommon`, ControlMultiselectCommon)
}
